import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/info.png';
const _hoisted_1 = {
  class: "max-440"
};
const _hoisted_2 = {
  class: "isCards"
};
const _hoisted_3 = {
  class: "buttons"
};
const _hoisted_4 = {
  key: 1,
  class: "AllInfo acea-row row-left"
};
import { router } from "@/router";
import { ref, watch } from "vue";
import { getCardData, getIdCard, getIdCardAdd } from "@/http";
import { showToast } from "vant";
import { useI18n } from "vue-i18n";
export default {
  __name: 'card',
  setup(__props) {
    const {
      t
    } = useI18n();
    const back = () => {
      router.back();
    };
    let form = ref({});
    let backCard = ref(2);
    let showDies = ref(false);
    const _getIdCard = () => {
      const loading = showToast({
        message: '',
        type: 'loading',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
        className: 'homeLoading'
      });
      getIdCard().then(res => {
        console.log(res);
        backCard.value = res.data;
        showDies.value = res.data === 1;
        if (res.data === 1) {
          getCardData().then(res => {
            form.value = res.data;
            const str = res.data.card;
            const str2 = res.data.name;
            form.value.card = '*'.repeat(str.length - 4) + str.slice(-4);
            form.value.name = str2.length > 2 ? str2.substring(0, 1) + '****' + str2.substring(str2.length - 1) : str2;
            loading.close();
          });
        } else {
          loading.close();
        }
      });
    };
    _getIdCard();
    const confirmOrder = () => {
      if (!form.value.bank_name) {
        showToast(t('newCard.cardNamePla'));
        return false;
      }
      if (!form.value.card) {
        showToast(t('newCard.numberPla'));
        return false;
      }
      if (!form.value.name) {
        showToast(t('newCard.namePla'));
        return false;
      }
      getIdCardAdd(form.value).then(res => {
        if (res.code === 200) {
          _getIdCard();
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          disabled: _unref(showDies),
          border: false,
          modelValue: _unref(form).bank_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(form).bank_name = $event),
          label: _ctx.$t('newCard.cardName'),
          placeholder: _ctx.$t('newCard.cardNamePla')
        }, null, 8, ["disabled", "modelValue", "label", "placeholder"]), _createVNode(_component_van_field, {
          disabled: _unref(showDies),
          border: false,
          modelValue: _unref(form).card,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(form).card = $event),
          label: _ctx.$t('newCard.number'),
          placeholder: _ctx.$t('newCard.numberPla')
        }, null, 8, ["disabled", "modelValue", "label", "placeholder"]), _createVNode(_component_van_field, {
          disabled: _unref(showDies),
          border: false,
          modelValue: _unref(form).name,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(form).name = $event),
          label: _ctx.$t('newCard.name'),
          placeholder: _ctx.$t('newCard.namePla')
        }, null, 8, ["disabled", "modelValue", "label", "placeholder"])]),
        _: 1
      })]), _createElementVNode("div", _hoisted_3, [_unref(backCard) !== 1 ? (_openBlock(), _createBlock(_component_van_button, {
        key: 0,
        color: "#56F9AA",
        style: {
          "color": "#333",
          "border-radius": "16px"
        },
        block: "",
        round: "",
        onClick: confirmOrder
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('newCard.save')), 1)]),
        _: 1
      })) : _createCommentVNode("", true), _unref(backCard) === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('newCard.customer_service')), 1)])) : _createCommentVNode("", true)])]);
    };
  }
};